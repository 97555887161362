// eslint-disable-next-line no-redeclare,no-unused-vars
function buildPopup(param) {
  // hide previous popup but keep in stack
  if (modalStack.length && !param.onTop) {
    var prev = $(modalStack).get(-1);
    prev.keepInStack = true;
    prev.modalObj.hide();
  }

  if (!param.ident) param.ident = 'popup' + rand();
  if (!param.closeLabel) param.closeLabel = 'Schließen';

  var modal = $(
    '<div class="modal fade" tabindex="-1" ident="' +
      param.ident +
      '" aria-labelledby="' +
      param.ident +
      '" aria-hidden="true"></div>'
  ).appendTo(document.body);
  modal.modal = true;
  param.view = modal;
  modal.ident = param.ident;
  if (param.overview) modal.overview = true;
  else if (param.detail) modal.detail = true;

  // add modal size (modal-xs, modal-sm, modal-md, modal-lg, modal-xxl)
  var size = 'modal-xxl';
  if (param.size) size = 'modal-' + param.size;
  var modalDialog = $(
    '<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable ' +
      size +
      '"></div>'
  ).appendTo(modal);

  if (param.position)
    modalDialog.addClass(['modal-side', 'modal-' + param.position]);

  modal.main = $('<div class="modal-content"></div>').appendTo(modalDialog);
  modal.header = $('<div class="modal-header"></div>').appendTo(modal.main);
  var modalTitle = $(
    '<h5 id="' + param.ident + '" class="modal-title pointer-move me-3"></h5>'
  ).appendTo(modal.header);
  addTitle(modal, modalTitle);
  modal.setTitle(param.title, getParamSettings(param, 'icon'));
  requestIdleCallback(function () {
    modal.main.draggable({handle: modalTitle});
  });

  // buttons
  modal.buttonsLeft = $(
    '<div class="action-buttons action-buttons-left me-auto"></div>'
  ).appendTo(modal.header);
  modal.buttonsRight = $(
    '<div class="action-buttons action-buttons-right"></div>'
  ).appendTo(modal.header);
  setTimeout(function () {
    if (getScreenWidth() > 992) {
      if (param.object && !param.noUpdate)
        $(
          '<button class="btn btn-light btn-default"><i class="icon fa-regular fa-sync fa-fw"></i></button>'
        )
          .appendTo(modal.buttonsRight)
          .on(click, function () {
            if (param.id == 'new') return;
            modal.update({update: true});
          })
          .addTooltip('Aktualisieren');

      $(
        '<button class="btn btn-light btn-default"><i class="icon fa-regular fa-times fa-fw"></i></button>'
      )
        .appendTo(modal.buttonsRight)
        .on(click, function () {
          modal.close();
        })
        .addTooltip(param.closeLabel + ' [Esc]');
    }
  }, 500);

  modal.body = $('<div class="modal-body"></div>').appendTo(modal.main);
  if (param.bodyCard || param.content) {
    var contCard = $('<div class="card"></div>').appendTo(modal.body);
    modal.body = $('<div class="card-body"></div>').appendTo(contCard);
  }
  if (param.noPadding) modal.body.addClass('p-0');
  else modal.body.addClass('p-4');
  if (param.content) modal.body.append(param.content);

  // footer
  var modalFooter = $('<div class="modal-footer"></div>').appendTo(modal.main);
  modal.statusbar = $('<div class="modal-status me-auto"></div>').appendTo(
    modalFooter
  );
  if (param.buttons)
    $.each(param.buttons, function (label, func) {
      $('<button class="btn btn-primary" type="button">' + label + '</button>')
        .appendTo(modalFooter)
        .on(click, function () {
          func(param);
        });
    });
  $(
    '<button class="btn btn-secondary" type="button">' +
      param.closeLabel +
      '</button>'
  )
    .appendTo(modalFooter)
    .on(click, function () {
      if (param.closeFunc) param.closeFunc(param);
      else modal.close();
    });

  // sidebar
  modal.sidebar = $('<div class="modal-sidebar d-none"></div>').appendTo(
    modal.main
  );
  modal.modalObj = new mdb.Modal(modal[0]);
  modal.modalObj.show();
  if (!param.onTop) modalStack.push(modal);

  // events
  modal.update = function (param2) {
    updateView(param, param2);
  };
  modal.close = function (evt) {
    if (modal.beforeClose) {
      var result = modal.beforeClose();
      if (result === false) return false;
    }
    if (!evt) return modal.modalObj.hide();

    if (param.close == 'all') modalStack = [];
    else if (!param.onTop) {
      modalStack.pop();
      if (param.close == 'prev') modalStack.pop();
      if (modalStack.length) {
        var prev = $(modalStack).get(-1);
        if (prev) prev.modalObj.show();
      }
    }

    if (param.afterClose) param.afterClose(modal);
    else if (modal.afterClose) modal.afterClose();

    // clear
    setTimeout(function () {
      modal.remove();
      if (!modalStack.length)
        $(document.body)
          .children('.modal,.modal-backdrop,.tox-tinymce-aux')
          .remove();
    }, 1000);
  };
  modal.on('hide.mdb.modal', function (evt) {
    if (modal.beforeClose) {
      var result = modal.beforeClose();
      if (result === false) return false;
    }

    modal.scrollPos = modal.body[0].scrollTop;
    if (modal.keepInStack) delete modal.keepInStack;
    else modal.close(evt);
  });
  modal.on('shown.mdb.modal', function () {
    if (modal.scrollPos) modal.body[0].scrollTop = modal.scrollPos;
    if (param.callback) param.callback();
  });

  return modal;
}
